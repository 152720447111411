import React from "react";
import locales from "../constants";
import { Layout, ContactFormDark } from "../components";
import { Link } from "gatsby";
import $ from "jquery";
import ReactPlayer from "react-player";

const KampanieFacebookAdsPage = ({ location: { pathname } }) => {
  if (typeof window !== `undefined`) {
    (function ($) {
      $.fn.shuffleLetters = function (prop) {
        var options = $.extend(
          {
            step: 1, // How many times should the letters be changed
            fps: 30, // Frames Per Second
            text: "", // Use this text instead of the contents
            callback: function () {}, // Run once the animation is complete
          },
          prop
        );

        return this.each(function () {
          var el = $(this),
            str = "";

          // Preventing parallel animations using a flag;
          if (el.data("animated")) {
            return true;
          }
          el.data("animated", true);

          if (options.text) {
            str = options.text.split("");
          } else {
            str = el.text().split("");
          }

          // The types array holds the type for each character;
          // Letters holds the positions of non-space characters;

          var types = [],
            letters = [];

          // Looping through all the chars of the string

          for (var i = 0; i < str.length; i++) {
            var ch = str[i];

            if (ch === " ") {
              types[i] = "space";
              continue;
            } else if (/[a-z]/.test(ch)) {
              types[i] = "lowerLetter";
            } else if (/[A-Z]/.test(ch)) {
              types[i] = "upperLetter";
            }

            letters.push(i);
          }

          el.html("");

          // Self executing named function expression:

          (function shuffle(start) {
            // This code is run options.fps times per second
            // and updates the contents of the page element

            var i,
              len = letters.length,
              strCopy = str.slice(0); // Fresh copy of the string

            if (start > len) {
              // The animation is complete. Updating the
              // flag and triggering the callback;

              el.data("animated", false);
              options.callback(el);
              return;
            }

            // All the work gets done here
            for (i = Math.max(start, 0); i < len; i++) {
              // The start argument and options.step limit
              // the characters we will be working on at once

              if (i < start + options.step) {
                // Generate a random character at thsi position
                strCopy[letters[i]] = randomChar(types[letters[i]]);
              } else {
                strCopy[letters[i]] = "";
              }
            }

            el.text(strCopy.join(""));

            setTimeout(function () {
              shuffle(start + 1);
            }, 2550 / options.fps);
          })(-options.step);
        });
      };

      function randomChar(type) {
        var pool = "";

        if (type === "lowerLetter") {
          pool = "abcdefghijklmnopqrstuvwxyz";
        } else if (type === "upperLetter") {
          pool = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
        }

        var arr = pool.split("");
        return arr[Math.floor(Math.random() * arr.length)];
      }
    })($);

    $(function () {
      var container = $("#shuffle");

      function shuffle(text) {
        container.shuffleLetters({
          text: text,
        });
      }

      var arr = [
        "rozpoznawalności marki",
        "kampania na zasięg",
        "generowanie ruchu",
        "budowanie społeczności",
        "interakcje pod postami",
        "pozyskiwanie leadów",
        "wyświetlanie filmu",
        "promocja lokalizacji",
        "wysyłanie wiadomości",
        "generowanie sprzedaży",
        "pobranie aplikacji",
        "pozyskanie konwersji",
      ];
      var i = 0;
      setInterval(function () {
        shuffle(arr[i++ % arr.length]);
      }, 2000);
    });
  }

  return (
    <Layout
      seo={{
        title: "Kampanie reklamowe Facebook Ads  | When",
        description:
          "Reklamy Facebook Ads pozwolą na dotarcie do Twoich klientów na Facebooku oraz Instagramie. Dzięki precyzyjnym opcjom targetowania Facebook Ads umożliwia skuteczne dotarcie do wybranej grupy odbiorców. Reklamuj się w social media. Zrealizuj z nami skuteczne kampanie reklamowe.",
        href: pathname,
        lang: "pl",
      }}
    >
      <span className="anchor" id="home"></span>
      <div className="subpage-header subpage-header--single-offer">
        <h1 className="subpage-header__title">
          Kampanie <br /> <span>Facebook Ads</span>
        </h1>
      </div>

      <div className="single-offer-menu-container">
        <div className="row">
          <div className="col-lg-3">
            <Link to="/uslugi/#ppc">
              <div className="back-wrapper">
                <span className="back-wrapper__arrow"></span>
                <p className="back-wrapper__title">Usługi</p>
              </div>
            </Link>
            <p className="nav-title">Specjalizacje:</p>
            <ul>
              <li>
                <Link to="/audyt-kampanii-google-facebook-ads/">
                  Audyt kampanii <br /> Google/Facebook Ads
                </Link>
              </li>
              <li>
                <Link to="/kampanie-googleads-search/">
                  Kampanie Google Ads search
                </Link>
              </li>
              <li>
                <Link to="/kampanie-wideo-na-youtube/">
                  Kampanie Youtube Ads
                </Link>
              </li>
              <li>
                <Link to="/kampanie-w-sieci-reklamowej-gdn/">Kampanie GDN</Link>
              </li>
              <li>
                <Link to="/kampanie-produktowe-product-listing-ads/">
                  Kampanie PLA
                </Link>
              </li>
              <li>
                <Link to="/tiktok-ads/">Kampanie TikTok Ads</Link>
              </li>
              <li className="current">
                <a href="#home">Kampanie Facebook Ads</a>
              </li>
              <li>
                <Link to="/kampanie-na-instagramie/">
                  Kampanie na Instagramie
                </Link>
              </li>
              <li>
                <Link to="/kampanie-linkedin-ads/">Kampanie LinkedIn</Link>
              </li>
              <li>
                <Link to="/banery-statyczne-i-dynamiczne/">
                  Banery statyczne i dynamiczne
                </Link>
              </li>
              <li>
                <Link to="/kampanie-zagraniczne/">
                  Kampanie PPC na rynki zagraniczne
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </div>

      <section className="single-offer-section-text-left single-offer-section-text-left--cat-2 single-offer-section-text-left--gdn">
        <div className="row">
          <div className="col-xl-8 offset-xl-4 col-lg-9 offset-lg-3">
            <div className="row align-items-center">
              <div className="col-lg-6 text-col">
                <h2 className="text-col__title mb-40">
                  Społeczność, której <br />
                  musisz być częścią
                </h2>
                <p className="text-col__desc">
                  Facebook przez lata stał się potęgą - zrzesza różne środowiska
                  ludzi, tworzy miejsce dla społeczności, jest platformą do
                  interakcji na miarę XXI wieku. To miejsce w Sieci, w którym po
                  prostu trzeba być. Potwierdza to liczba użytkowników portalu -
                  aktywnych w ciągu miesiąca jest 2,6 miliarda użytkowników na
                  całym świecie. To właśnie sprawia, że Facebook to również
                  platformą, która pomoże zrealizować cele biznesowe Twojej
                  firmy.
                </p>
              </div>
              <div className="col-lg-6 img-col">
                <img
                  className="img-fluid img-col__image"
                  src={require("../assets/images/kampanie-facebook-img-1.jpg")}
                  alt=""
                />
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="single-offer-section-text-right">
        <div className="row">
          <div className="col-xl-9 offset-xl-3 col-lg-9 offset-lg-3">
            <div className="row align-items-center">
              <div className="col-lg-5 img-col">
                <img
                  className="img-fluid img-col__image"
                  src={require("../assets/images/kampanie-facebook-img-2.jpg")}
                  alt=""
                />
              </div>
              <div className="col-lg-6 text-col">
                <h2 className="text-col__title mb-40">
                  Precyzyjne targetowanie
                </h2>
                <p className="text-col__desc">
                  Facebook wie bardzo dużo o swoich użytkownikach - analizuje
                  to, co publikują na portalu, ich polubienia, wydarzenia z
                  życia i podróże. Przy wykorzystaniu zebranych danych
                  kierowanie reklam na portalu jest bardzo dokładne. Jest też
                  wyjątkowo szczegółowe. Możemy, oprócz targetowania po
                  standardowych kategoriach, takich jak płeć, wiek czy
                  lokalizacja, kierować nasze reklamy na osoby dokonujące
                  zakupów impulsywnie, kobiety w ciąży, czy do osób
                  zainteresowanych inwestowaniem odpowiedzialnym społecznie.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="single-offer-section-text single-offer-section-text--kampanie-na-ig">
        <div className="row">
          <div className="col-lg-8 offset-lg-3 text-col">
            <h2 className="text-col__title">
              <span>
                Kampanie dopasowane <br /> do Twoich celów:
              </span>{" "}
              <br />
              <span id="shuffle">rozpoznawalność marki</span>
            </h2>
          </div>
        </div>
      </section>
      <section className="single-offer-section-text-left single-offer-section-text-left--kampanie-na-ig">
        <div className="row">
          <div className="col-xl-8 offset-xl-4 col-lg-9 offset-lg-3">
            <div className="row align-items-center">
              <div className="col-lg-11 text-col">
                <h2 className="text-col__title mb-40">
                  Jedna platforma, wiele celów reklamowych
                </h2>
                <p className="text-col__desc">
                  Facebook w ramach swojego systemu oferuje wiele formatów
                  reklamowych, które charakteryzują się odmiennymi celami
                  reklamy. Do wyboru mamy między innymi kampanię zasięgową,
                  kampanie leadową, czy sprzedaż z katalogu.
                  <br />
                  <br />
                  Wybranie odpowiedniego celu jest istotne. Algorytm Facebooka
                  będzie optymalizował kampanię pod wybrany przez nas cel. Na
                  przykład po wybraniu celu “Instalacje aplikacji” reklama
                  będzie wyświetlać się użytkownikom nie tylko ze wskazanymi
                  przez nas cechami i zainteresowaniami, ale wśród tych osób
                  znajdzie te, które w przeszłości pobierały już inne aplikacje.
                  Pozwoli to zmaksymalizować efekty kampanii i zmniejszyć ryzyko
                  przepalenia budżetu na wyświetlenie reklamy użytkownikom,
                  którzy naszego celu na pewno nie zrealizują.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="single-offer-section-single-video single-offer-section-single-video--kampanie-fb">
        <div className="row row--single-video">
          <div className="col-xl-8 offset-xl-4 col-lg-9 offset-lg-3">
            <div className="row">
              <div className="col-md-7 col-md-3 col-sm-8 offset-sm-4 single-offer-section-single-video__text-col">
                <h2 className="single-offer-section-single-video__title">
                  Reklama z kolekcją produktów, tworzona na bazie katalogu
                  produktów klienta
                </h2>
              </div>
            </div>
            <div className="video-container">
              <ReactPlayer
                url="https://when.zenx.pl/wp-content/themes/adream/assets/video/fb-3.mp4"
                playing="true"
                loop="true"
                muted="true"
                width="244px"
                height="506px"
                playsinline="true"
              />
            </div>
          </div>
        </div>
        <div className="row row--single-video">
          <div className="col-xl-8 offset-xl-4 col-lg-9 offset-lg-3">
            <div className="row">
              <div className="col-md-7 col-md-3 col-sm-8 offset-sm-4 single-offer-section-single-video__text-col">
                <h2 className="single-offer-section-single-video__title">
                  Reklama z karuzelą produktów
                </h2>
                <p className="single-offer-section-single-video__desc">
                  Może być wygenerowana automatycznie na bazie katalogu
                  produktów lub przygotowana ręcznie w managerze reklam
                </p>
              </div>
            </div>
            <div className="video-container">
              <ReactPlayer
                url="https://when.zenx.pl/wp-content/themes/adream/assets/video/fb-2.mp4"
                playing="true"
                loop="true"
                muted="true"
                width="244px"
                height="506px"
                playsinline="true"
              />
            </div>
          </div>
        </div>
        <div className="row row--single-video">
          <div className="col-xl-8 offset-xl-4 col-lg-9 offset-lg-3">
            <div className="row">
              <div className="col-md-7 col-md-3 col-sm-8 offset-sm-4 single-offer-section-single-video__text-col">
                <h2 className="single-offer-section-single-video__title">
                  Reklama mająca na celu pozyskiwanie kontaktów z natywnym
                  formularzem wewnątrz reklamy
                </h2>
              </div>
            </div>
            <div className="video-container">
              <ReactPlayer
                url="https://when.zenx.pl/wp-content/themes/adream/assets/video/fb-1.mp4"
                playing="true"
                loop="true"
                muted="true"
                width="244px"
                height="506px"
                playsinline="true"
              />
            </div>
          </div>
        </div>
      </section>
      <section className="single-offer-section-text-small">
        <div className="row">
          <div className="col-lg-6 offset-lg-4 text-col">
            <h2 className="text-col__title">
              Reklama to za mało? <br />
              Sprawdź naszą propozycję na prowadzenie <br />
              strony Twojej firmy na <span>Facebooku</span>
            </h2>
            <div className="text-wrapper--inner-action">
              <Link
                to="/prowadzenie-strony-na-facebooku/"
                className="btn btn-line btn-download-portfolio"
              >
                prowadzenie strony na <strong>Facebooku</strong>
              </Link>
            </div>
          </div>
        </div>
      </section>
      <section className="single-offer-section-case-study">
        <div className="row">
          <div className="col-xl-7 offset-xl-4 col-lg-9 offset-lg-3 case-col">
            <h2 className="case-col__title">
              Sprawdź nasze przykładowe <br /> case studies
            </h2>
            <div className="post-wrapper">
              <div className="post-wrapper__thumbnail-container thumbnail-container">
                <div className="rectangle"></div>
                <img
                  className="img-fluid thumbnail-container__image image"
                  src={require("../assets/images/strategy-sm-case-1.png")}
                  alt=""
                />
              </div>
              <p className="post-wrapper__category">branża hotelarska</p>
              <p className="post-wrapper__title">
                Społeczność <span>30 000</span> zaangażowanych fanów obiektu
                hotelowego Blue Mountain Resort w ciągu 2 lat.
              </p>
              <p className="post-wrapper__desc">
                Praca nad budową społeczności od zera to nasze ulubione zadanie.
                Sprawdź nasze case study prowadzenia strony na Facebooku dla
                4-gwiazdkowego hotelu wypoczynkowego Blue Mountain Resort od
                pierwszego dnia istnienia obiektu.
              </p>
              <div className="text-wrapper--inner-action">
                <Link
                  to="/case-study/blue-mountain-resort/"
                  className="btn btn-line"
                >
                  więcej
                </Link>
              </div>
            </div>
          </div>
          <div className="col-xl-7 offset-xl-4 col-lg-9 offset-lg-3 case-col">
            <div className="post-wrapper">
              <div className="post-wrapper__thumbnail-container thumbnail-container">
                <div className="rectangle"></div>
                <img
                  className="img-fluid thumbnail-container__image image"
                  src={require("../assets/images/strategy-sm-case-2.png")}
                  alt=""
                />
              </div>
              <p className="post-wrapper__category">branża nieruchomości</p>
              <p className="post-wrapper__title">
                Magiczna kraina, fascynująca kultura i tylko 44 grosze za
                pozyskanie nowego fana strony na Facebooku Amazing Thailand
                Poland
              </p>
              <p className="post-wrapper__desc">
                Zobacz nasze case study z 2-letniej współpracy nad budową
                społeczności użytkowników zakochanych w Tajlandii na zlecenie
                Tourism Authority of Thailand
              </p>
              <div className="text-wrapper--inner-action">
                <Link to="/amazing-thailand-poland/" className="btn btn-line">
                  więcej
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="single-offer-section-contact">
        <div className="form-wrapper">
          <h2 className="single-offer-section-contact__title mb-40">
            Skontaktuj się z nami! <br />
            Wypełnij <span>formularz</span>
          </h2>
          <ContactFormDark locales={locales["pl"]} messageRequired={false} />
        </div>
        <img
          className="img-fluid single-offer-section-contact__hand"
          src={require("../assets/images/home-contact-hand-right.png")}
          alt=""
        />
      </section>
    </Layout>
  );
};
export default KampanieFacebookAdsPage;
